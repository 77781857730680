<app-details-box [title]="'Current Status'">

  @if(isInitialLoading ){
    <app-main-loader></app-main-loader>
  }@else if(latestDetails){
    <!-- LATEST STATUS DETAILS BOX -->
    <div class="row">
       <div class="col-md-3 col-sm-6">
         <app-label-display
           [label]="'Status'"
           [type]="'status'"
           [status]="latestDetails.status">
         </app-label-display>
       </div>
       <div class="col-md-3 col-sm-6">
         <app-label-display
           [type]="'user'"
           [label]="'Updated By'"
           [user]="latestDetails.updated_by || null">
         </app-label-display>
       </div>
       <div class="col-md-3 col-sm-6">
         <app-label-display
           [type]="'datetime'"
           [label]="'Updated At'"
           [value]="latestDetails.updated_at || null">
         </app-label-display>
       </div>
       <div class="col-md-3 col-sm-6">
        <app-label-display
          [type]="'text'"
          [label]="'Percentage'"
          [value]="latestDetails.percentage+'%' || null">
        </app-label-display>
      </div>
       <div class="col-md-12 col-sm-12">
         <app-label-display
           [label]="'Comment'"
           [type]="'para'"
           [value]="latestDetails.comment">
         </app-label-display>
       </div>
     </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="grey-hr" />
    </div>
  </div>

  <!-- STATUS UPDATE FORM -->
  <div class="col-md-12" style="cursor: pointer">
    <app-toggle-button
      [isOpenForm]="isShowStatusForm"
      (toggleRequest)="requestToggleStatusForm()">
    </app-toggle-button>
  </div>

  @if(isShowStatusForm){
    <div class="col-md-8">
      <app-side-form
        [buttonPosition]="'bottom'"
        [submitButtonTitle]="'Update'"
        [submitButtonLoadingText]="'Updating'"
        [cancelButtonTitle]="'Cancel'"
        [formGroup]="formGroup"
        [formSections]="formSections"
        [isLoading]="isFormDataLoading"
        (formSubmitRequest)="requestSubmitForm()"
        (closeRequest)="requestCloseStatusForm()">
      </app-side-form>
    </div>
  }

  <div class="col-md-12">
    <hr class="grey-hr" />
  </div>

  <!-- STATUS UPDATE HISTORY -->
    <div class="col-md-12" style="cursor: pointer">
      <p class="fw-500" (click)="requestToggleStatusHistory()">
        <img src="assets/images/icons/icon-down-charet.svg" class="me-1"/>
        View History
      </p>
    </div>
    @if(isShowHistory){
      <div class="col-md-12">
      ` <app-basic-table
          [isLoading]="isHistoryLoading"
          [data]="history"
          [columns]="historyColumns"
          [isPaginationEnabled]="false">
        </app-basic-table>
      </div>
     }
  }
</app-details-box>
